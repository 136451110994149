<template>
  <div class="store-manage">
    <app-block-flex>
      <app-query-bar
        :queryConfig="queryConfig"
        v-model="queryData"
        @onSearch="fetchStores"/>
    </app-block-flex>
    <app-block-flex>
      <app-spacing>
        <Button
          v-if="permissions[STORE_ACTION.CREATE]"
          type="warning"
          @click="() => onStoreAction(STORE_ACTION.CREATE, true)">
          {{ STORE_ACTION_TEXT[STORE_ACTION.CREATE] }}
        </Button>
        <Button
          v-if="permissions[STORE_ACTION.REBIND]"
          type="primary"
          :disabled="allowedRebind"
          @click="() => onStoreAction(STORE_ACTION.REBIND, true)">
          {{ STORE_ACTION_TEXT[STORE_ACTION.REBIND] }}
        </Button>
      </app-spacing>
      <app-export-bar
        @onExport="onExport"
        @onExportAll="onExportAll"/>
    </app-block-flex>
    <app-data-table
      selectable
      :columns="STORE_COLUMNS"
      :data="stores"
      :allowed-permissions="Object.values(ALLOWED_PERMISSION)"
      v-model="queryData"
      @onSelect="onSelect"
      @onPageConfigChange="fetchStores">
      <template
        slot-scope="{ row }"
        slot="level">
        {{ LEVEL_TEXT[row.level] }}
      </template>
      <template
        slot-scope="{ row }"
        slot="status">
        {{ STATUS_TEXT[row.status] }}
      </template>
      <app-spacing
        class="table-action"
        slot-scope="{ row }"
        slot="action">
        <Button
          v-if="permissions[STORE_ACTION.EDIT]"
          type="primary"
          @click="() => onStoreAction(STORE_ACTION.EDIT, true, row.id)">
          编辑
        </Button>
        <Button
          type="primary"
          @click="() => onStoreAction(STORE_ACTION.COMMON_STORE, true, row.id,row.status,row)">
          通用门店
        </Button>
        <Button
          type="primary"
          @click="() => onStoreAction(STORE_ACTION.OPTOMETRIST, true, row.id)">
          视光师
        </Button>
        <Button
          type="primary"
          @click="() => handleStoreQrCode(row)">
          门店码
        </Button>
        <Button
          type="primary"
          @click="() => onStoreAction(STORE_ACTION.RESET, true, row.id)">
          重置密码
        </Button>
        <Button
          v-if="permissions[STORE_ACTION.TOGGLE_STATUS]"
          :type="row.status === STATUS.ENABLE ? 'error' : 'primary'"
          @click="() => onStoreAction(STORE_ACTION.TOGGLE_STATUS, true, row.id, row.status)">
          {{ REVERSE_STATUS_TEXT[row.status] }}
        </Button>
      </app-spacing>
    </app-data-table>
    <app-modal
      :value="storeModalConfig.visible"
      :title="storeActionText"
      :width="modalWidth"
      :footerHide="
        storeModalConfig.action === STORE_ACTION.COMMON_STORE ||
        storeModalConfig.action === STORE_ACTION.OPTOMETRIST
      "
      @on-ok="onModalConfirm"
      @on-cancel="() => onStoreAction(null, false)">
      <Form
        ref="storeForm"
        :model="storeModalData"
        :rules="storeModalRule"
        :label-position="labelPosition"
        :label-width="labelWidth">
        <template v-if="storeModalConfig.action === STORE_ACTION.REBIND">
          <Alert type="warning">更换渠道时，会将门店以及子孙门店从所在的通用门店中解绑</Alert>
          <FormItem
            label="请选择渠道或门店作为新的直属上级"
            prop="bindType">
            <app-spacing class="rebind-form-content">
              <Select
                v-model="storeModalData.bindType">
                <Option
                  v-for="item in REBIND_TYPE_OPTIONS"
                  :value="item.value"
                  :key="item.value">
                  {{ item.label }}
                </Option>
              </Select>
              <Select
                filterable
                clearable
                v-if="storeModalData.bindType === REBIND_TYPE.PROVIDE"
                v-model="storeModalData.newBindStoreId">
                <Option
                  v-for="item in storeModalConfig.storeOptions"
                  :value="item.value"
                  :key="item.value">
                  {{ item.label }}
                </Option>
              </Select>
              <Select
                filterable
                clearable
                v-else-if="storeModalData.bindType === REBIND_TYPE.CHANNEL"
                v-model="storeModalData.newBindChannelId">
                <Option
                  v-for="item in storeModalConfig.channelOptions"
                  :value="item.value"
                  :key="item.value">
                  {{ item.label }}
                </Option>
              </Select>
            </app-spacing>
          </FormItem>
        </template>
        <template v-else-if="
            storeModalConfig.action === STORE_ACTION.CREATE ||
            storeModalConfig.action === STORE_ACTION.EDIT
          ">
          <FormItem
            v-if="storeModalConfig.action === STORE_ACTION.EDIT"
            label="门店ID">
            {{ storeModalData.id }}
          </FormItem>
          <FormItem
            label="门店名称"
            prop="name">
            <Input
              v-model="storeModalData.name"
              :placeholder="TIPS.NAME"/>
          </FormItem>
          <FormItem
            label="门店管理员"
            prop="nickName">
            <Input
              v-model="storeModalData.nickName"
              :placeholder="TIPS.NICKNAME"/>
          </FormItem>
          <FormItem
            v-if="storeModalConfig.action === STORE_ACTION.CREATE"
            label="管理员手机号"
            prop="mobile">
            <Input
              v-model="storeModalData.mobile"
              :placeholder="TIPS.MOBILE"/>
          </FormItem>
          <FormItem
            v-else-if="storeModalConfig.action === STORE_ACTION.EDIT"
            label="管理员手机号"
            prop="mobile">
            <!-- {{ storeModalData.mobile }} -->
            <Input
              v-model="storeModalData.mobile"
              :placeholder="TIPS.MOBILE"/>
          </FormItem>
          <FormItem
            label="省份"
            prop="province">
            <Select
              v-model="storeModalData.province"
              style="width:200px"
              placeholder="请选择省/市">
              <Option
                v-for="item in cities" :value="item" :key="item">{{ item }}
              </Option>
            </Select>
          </FormItem>
          <FormItem
            v-if="
              storeModalConfig.action === STORE_ACTION.CREATE
              && userType === USER_TYPE.ADMIN
            "
            label="直属上级"
            prop="parentId">
            <app-spacing class="rebind-form-content">
              <Select
                v-model="storeModalData.bindType"
                @on-change="onBindTypeChange">
                <Option
                  v-for="item in ZHISHU_TYPE_OPTIONS"
                  :value="item.value"
                  :key="item.value">
                  {{ item.label }}
                </Option>
              </Select>
              <Select
                clearable
                v-if="storeModalData.bindType === REBIND_TYPE.PROVIDE"
                v-model="storeModalData.parentId">
                <Option
                  v-for="item in storeModalConfig.storeOptions"
                  :value="item.value"
                  :key="item.value">
                  {{ item.label }}
                </Option>
              </Select>
              <Select
                clearable
                v-else-if="storeModalData.bindType === REBIND_TYPE.CHANNEL"
                v-model="storeModalData.channelId">
                <Option
                  v-for="item in storeModalConfig.channelOptions"
                  :value="item.value"
                  :key="item.value">
                  {{ item.label }}
                </Option>
              </Select>
            </app-spacing>
          </FormItem>
          <!--          <FormItem-->
          <!--            v-if="storeModalConfig.action ===
          STORE_ACTION.CREATE && userType === USER_TYPE.OUTLET"-->
          <!--            label="直属上级"-->
          <!--            prop="parentId">-->
          <!--            <Select-->
          <!--              filterable-->
          <!--              clearable-->
          <!--              v-model="storeModalData.parentId">-->
          <!--              <Option-->
          <!--                v-for="item in storeModalConfig.storeOptions"-->
          <!--                :value="item.value"-->
          <!--                :key="item.value">-->
          <!--                {{ item.label }}-->
          <!--              </Option>-->
          <!--            </Select>-->
          <!--          </FormItem>-->
          <!--          <FormItem-->
          <!--            v-if="storeModalConfig.action ===
           STORE_ACTION.CREATE && userType === USER_TYPE.CHANNEL"-->
          <!--            label="直属上级"-->
          <!--            prop="parentId">-->
          <!--            <Select-->
          <!--              filterable-->
          <!--              clearable-->
          <!--              v-model="storeModalData.channelId">-->
          <!--              <Option-->
          <!--                v-for="item in storeModalConfig.channelOptions"-->
          <!--                :value="item.value"-->
          <!--                :key="item.value">-->
          <!--                {{ item.label }}-->
          <!--              </Option>-->
          <!--            </Select>-->
          <!--          </FormItem>-->
          <FormItem
            label="地址"
            prop="address">
            <Input
              :maxlength=50
              v-model="storeModalData.address"
              :placeholder="TIPS.ADDRESS"/>
          </FormItem>
          <FormItem
            label="联系人邮箱"
            prop="email">
            <Input
              v-model="storeModalData.email"
              :placeholder="TIPS.EMAIL"/>
          </FormItem>
          <FormItem
            label="单次训练价格"
            prop="trainPrice">
            <InputNumber
              class="divide-ratio"
              :min="0"
              v-model="storeModalData.trainPrice"
              :placeholder="TIPS.TRAIN_PRICE"/>
          </FormItem>
          <FormItem
            label="分成比例"
            prop="divideRatio">
            <InputNumber
              class="divide-ratio"
              :min="0"
              v-model="storeModalData.divideRatio"
              :placeholder="TIPS.DIVIDE_RATIO"/>
          </FormItem>
          <FormItem
            label="开户银行"
            prop="bank">
            <Input
              :maxlength=50
              v-model="storeModalData.bank"
              :placeholder="TIPS.BANK"/>
          </FormItem>
          <FormItem
            label="开户银行账号"
            prop="bankAccount">
            <Input
              v-model="storeModalData.bankAccount"
              :placeholder="TIPS.BANK_ACCOUNT"/>
          </FormItem>
          <FormItem
            label="企业开户名称"
            prop="bankBusinessName">
            <Input
              :maxlength=50
              v-model="storeModalData.bankBusinessName"
              :placeholder="TIPS.BANK_BUSINESS_NAME"/>
          </FormItem>
          <FormItem
            label="训练预设方案个数"
            prop="presetLimit">
            <InputNumber
              :max="99"
              :min="1"
              v-model="storeModalData.presetLimit"/>
          </FormItem>
          <FormItem
            label="检查预设方案个数"
            prop="examPresetLimit">
            <InputNumber
              :max="99"
              :min="1"
              v-model="storeModalData.examPresetLimit"/>
          </FormItem>
        </template>
        <template v-else-if="storeModalConfig.action === STORE_ACTION.COMMON_STORE">
          <template>
            <div v-if="permissions[ALLOWED_PERMISSION.COMMON_STORE_BIND] && userType !== 'OUTLET'">
              <FormItem label="搜索">
                <app-spacing class="modal-search-bar">
                  <Input v-model="storeModalConfig.commonStoreKeyword"/>
                  <Button
                    type="primary"
                    @click="getUnbindStoreList">
                    搜索
                  </Button>
                </app-spacing>
              </FormItem>
              <FormItem label="姓名">
                <app-spacing>
                  <RadioGroup v-model="storeModalConfig.storeId">
                    <Radio v-for="item in storeModalConfig.unBindStore"
                           :key="item.id"
                           :label="item.id"
                           :value="item.id">
                      <span>{{ item.name }}</span>
                    </Radio>
                  </RadioGroup>
                  <app-spacing>
                    <Button
                      type="primary"
                      @click="bindStores()">
                      添加
                    </Button>
                  </app-spacing>
                </app-spacing>
              </FormItem>
            </div>
            <app-table
              :columns="COMMON_STORE_COLUMNS"
              :data="storeModalConfig.commonStores"
              :allowedPermissions="[ALLOWED_PERMISSION.COMMON_STORE_UNBIND]">
              <template
                slot-scope="{ row }"
                slot="opt_group_type">
                {{ groupType[row.opt_group_type] }}
              </template>
              <app-spacing
                class="table-action"
                slot-scope="{ row }"
                slot="action">
                <Button
                  v-if="permissions[ALLOWED_PERMISSION.COMMON_STORE_UNBIND] &&
                    userType !== 'OUTLET'"
                  type="primary"
                  @click="() => onUnBindAction(
                      ALLOWED_PERMISSION.COMMON_STORE_UNBIND,
                      row.store_id
                    )">
                  解绑
                </Button>
              </app-spacing>
            </app-table>
          </template>
        </template>
        <template v-else-if="storeModalConfig.action === STORE_ACTION.OPTOMETRIST">
          <template v-if="permissions[ALLOWED_PERMISSION.OPTOMETRIST_BIND]">
            <FormItem label="搜索">
              <app-spacing class="modal-search-bar">
                <Input v-model="storeModalConfig.optometristKeyword"/>
                <Button
                  type="primary"
                  @click="getUnbindSgsList">
                  搜索
                </Button>
              </app-spacing>
            </FormItem>
            <FormItem label="姓名">
              <app-spacing>
                <CheckboxGroup v-model="storeModalConfig.sgsIds">
                  <Checkbox v-for="item in storeModalConfig.unBindSgs"
                            :key="item.id"
                            :label="item.id"
                            :value="item.id">
                    <span>{{ item.nick_name }}</span>
                    <span>({{ item.mobile }})</span>
                  </Checkbox>
                </CheckboxGroup>
                <app-spacing>
                  <Button
                    type="primary"
                    @click="bindSgs()">
                    添加
                  </Button>
                </app-spacing>
              </app-spacing>
            </FormItem>
            <div class="modalTable"
            >
              <app-table
                :columns="OPTOMETRIST_COLUMNS"
                :data="storeModalConfig.optometrists"
                :allowedPermissions="[ALLOWED_PERMISSION.OPTOMETRIST_UNBIND]">
                <app-spacing
                  class="table-action"
                  slot-scope="{ row }"
                  slot="action">
                  <Button
                    v-if="permissions[ALLOWED_PERMISSION.OPTOMETRIST_UNBIND]"
                    type="primary"
                    @click="() => onUnBindAction(
                      ALLOWED_PERMISSION.OPTOMETRIST_UNBIND,
                      row.id
                    )">
                    解绑
                  </Button>
                </app-spacing>
              </app-table>

            </div>
          </template>
        </template>
        <template v-else-if="storeModalConfig.action === STORE_ACTION.TOGGLE_STATUS">
          <template v-if="storeModalData.status === STATUS.ENABLE">
            门店停用后，该门店将与通用门店解绑，且本门店的视光师也不能再进行快视科技的业务操作，是否停用该门店？
          </template>
        </template>
        <template v-else-if="storeModalConfig.action === STORE_ACTION.RESET ">
          <p>
            重置后，该门店登录密码将恢复为系统默认登录密码，是否继续操作？
          </p>
        </template>
      </Form>
    </app-modal>

    <!-- 门店码弹框 -->
    <app-modal
      :value="storeQrCodeModalConfig.visible"
      title="门店码"
      :width="400"
      :footerHide="true"
      @on-cancel="() => storeQrCodeModalConfig.visible = false">
      <div class="store-qrcode-modal">
        <qrcode-vue v-if="storeQrCodeModalConfig.visible"
         :value="storeQrCodeModalConfig.value"
          :size="300"
           level="H"
        />
        <div>
          <h3>门店名称：{{ storeQrCodeModalConfig.name }}</h3>
          <h3>门店地址：{{ storeQrCodeModalConfig.address }}</h3>
        </div>
      </div>
    </app-modal>

  </div>
</template>

<script>
import QrcodeVue from 'qrcode.vue';
import {
  STORE_COLUMNS,
  COMMON_STORE_COLUMNS,
  OPTOMETRIST_COLUMNS,
  ALLOWED_PERMISSION,
  LEVEL_TEXT,
  STATUS,
  STATUS_TEXT,
  STATUS_OPTIONS,
  STORE_ACTION,
  STORE_ACTION_TEXT,
  REVERSE_STATUS_TEXT,
  REBIND_TYPE,
  REBIND_TYPE_OPTIONS,
  ZHISHU_TYPE_OPTIONS,
  TIPS,
  groupType,
  cities,
} from './constants';
import { USER_TYPE } from '../../login/constants';
import StoreManageApi from './store-manage.api';
import ChannelManageApi from '../channel-manage/channel-manage.api';
import DataTransferUtil from '../../../utils/data-transfer.util';

export default {
  name: 'store-manage',
  components: {
    QrcodeVue,
  },
  computed: {
    userType() {
      return this.$store.getters.userType;
    },
    storeActionText() {
      return STORE_ACTION_TEXT[this.storeModalConfig.action] + (this.title ? `: ${this.title}` : '');
    },
    allowedRebind() {
      const { selectStoreIds, bindChannelId, bindStoreId } = this.storeModalData;

      return !((bindChannelId?.toString() || bindStoreId?.toString())
        && selectStoreIds.length !== 0);
    },
    modalWidth() {
      if (
        this.storeModalConfig.action === STORE_ACTION.COMMON_STORE
        || this.storeModalConfig.action === STORE_ACTION.OPTOMETRIST
      ) {
        return 920;
      }

      return 600;
    },
    labelPosition() {
      return this.storeModalConfig.action === STORE_ACTION.REBIND
        ? 'top' : 'left';
    },
    labelWidth() {
      if (this.storeModalConfig.action === STORE_ACTION.REBIND) {
        return null;
      }

      if (
        this.storeModalConfig.action === STORE_ACTION.COMMON_STORE
        || this.storeModalConfig.action === STORE_ACTION.OPTOMETRIST
      ) {
        return 40;
      }

      return 130;
    },
    queryConfig() {
      const result = [
        {
          fieldName: 'channelId',
          name: '直属渠道',
          type: 'select',
          show: this.userType === 'OUTLET',
          options: this.queryConfigChannelOptions,
        },
        {
          fieldName: 'parentStoreId', // 运营模式可见
          name: '上级门店',
          type: 'select',
          options: this.storeModalConfig.storeOptions,
        },
        {
          fieldName: 'name',
          name: '门店名',
        },
        {
          fieldName: 'status',
          name: '状态',
          type: 'select',
          options: STATUS_OPTIONS,
        },
      ];
      if (!this.permissions[STORE_ACTION.REBIND]) {
        result.splice(result.findIndex((item) => item.fieldName === 'parentStoreId'), 1);
      }

      return result;
    },
    STORE_COLUMNS() {
      const allowedPermissionValues = [
        STORE_ACTION.EDIT,
        STORE_ACTION.TOGGLE_STATUS,
        STORE_ACTION.RESET,
      ];
      const count = Object
        .keys(this.permissions)
        .filter((k) => allowedPermissionValues.includes(k))
        .length;
      const columns = [...STORE_COLUMNS];
      columns.map((column) => {
        if (column.slot === 'action') {
          // eslint-disable-next-line no-param-reassign
          column.width = count * 86 + 214 + 80;
        }
        if (column.slot === 'action' && this.$store.getters.userType === USER_TYPE.CHANNEL) {
          // eslint-disable-next-line no-param-reassign
          column.width = 400 + 80;
        } else if (column.slot === 'action' && this.$store.getters.userType === USER_TYPE.OUTLET) {
          // eslint-disable-next-line no-param-reassign
          column.width = 400 + 80;
        }

        return column;
      });
      return columns;
    },

  },
  data() {
    return {
      cities,
      groupType,
      COMMON_STORE_COLUMNS,
      OPTOMETRIST_COLUMNS,
      ALLOWED_PERMISSION,
      STORE_ACTION,
      STATUS_TEXT,
      LEVEL_TEXT,
      STATUS,
      STORE_ACTION_TEXT,
      REVERSE_STATUS_TEXT,
      REBIND_TYPE,
      REBIND_TYPE_OPTIONS,
      ZHISHU_TYPE_OPTIONS,
      TIPS,
      USER_TYPE,
      title: '',
      queryConfigChannelOptions: [],
      queryData: {
        page: 1,
        limit: 10,
        count: 0,
        channelId: '',
        parentStoreId: '',
        name: '',
        status: STATUS.ALL,
      },
      stores: [],
      storeModalConfig: {
        id: NaN,
        visible: false,
        action: null,
        commonStoreKeyword: '',
        optometristKeyword: '',
        storeOptions: [],
        channelOptions: [],
        commonStores: [],
        optometrists: [],
        unBindSgs: [],
        unBindStore: [],
        sgsIds: [],
        storeId: null,
      },
      storeModalData: {
        id: null,
        name: '',
        nickName: '',
        mobile: '',
        province: '',
        parentId: null,
        channelId: null, // TODO: 无效字段
        address: '',
        email: '',
        trainPrice: null,
        divideRatio: null,
        bank: '',
        bankAccount: '',
        bankBusinessName: '',
        bindType: REBIND_TYPE.CHANNEL,
        bindStoreId: null, // TODO: 不同的ID 无效字段
        newBindStoreId: null,
        bindChannelId: null,
        newBindChannelId: null,
        selectStoreIds: [],
        commonStoreId: null,
        optometristId: null,
        status: STATUS.ENABLE,
      },
      storeModalRule: {
        bindType: [
          { required: true, message: TIPS.REBIND_TYPE, trigger: 'change' },
          {
            checkedFields: {
              [REBIND_TYPE.PROVIDE]: 'newBindStoreId',
              [REBIND_TYPE.CHANNEL]: 'newBindChannelId',
            },
            trigger: 'change',
            validator: this.rebindModalValidator,
          },
        ],
        name: [{ required: true, message: TIPS.NAME, trigger: 'change' }],
        nickName: [{ required: true, message: TIPS.NICKNAME, trigger: 'change' }],
        mobile: [{ required: true, message: TIPS.MOBILE, trigger: 'change' }],
        province: [{ required: true, message: TIPS.PROVINCE, trigger: 'change' }],
        parentId: [{
          required: true,
          trigger: 'change',
          validator: this.createModalValidator,
        }],
        address: [{ required: true, message: TIPS.ADDRESS, trigger: 'change' }],
        trainPrice: [{
          required: true,
          type: 'number',
          message: TIPS.TRAIN_PRICE,
          trigger: 'change',
        }],
        divideRatio: [{
          required: true,
          type: 'number',
          message: TIPS.DIVIDE_RATIO,
          trigger: 'change',
        }],
      },
      storeQrCodeModalConfig: {
        visible: false,
        value: '',
        name: '',
        address: '',
      },
    };
  },
  created() {
    this.fetchParentStores();
    if (this.userType !== 'OUTLET') {
      this.fetchParentChannels();
    }
    this.fetchStores();
  },
  methods: {
    resetStoreModalData() {
      this.storeModalData = {
        id: null,
        name: '',
        nickName: '',
        mobile: '',
        province: '',
        parentId: null,
        channelId: null, // TODO: 无效字段
        address: '',
        email: '',
        trainPrice: null,
        divideRatio: null,
        bank: '',
        bankAccount: '',
        bankBusinessName: '',
        bindType: REBIND_TYPE.CHANNEL,
        bindStoreId: null, // TODO: 不同的ID 无效字段
        newBindStoreId: null,
        bindChannelId: null,
        newBindChannelId: null,
        selectStoreIds: [],
        commonStoreId: null,
        optometristId: null,
        status: STATUS.ENABLE,
      };
    },
    async fetchStores() {
      const {
        page,
        limit,
        channelId,
        name,
        status,
        parentStoreId,
      } = this.queryData;

      this.storeModalData = {
        ...this.storeModalData,
        bindChannelId: channelId,
        bindStoreId: parentStoreId,
        newBindChannelId: null,
        newBindStoreId: null,
        selectStoreIds: [],
      };

      const {
        count = 0,
        results = [],
      } = await StoreManageApi.getStores(page, limit, name, channelId, null, status, parentStoreId);
      this.stores = results;
      this.$set(this.queryData, 'count', count);
    },
    async fetchStoreDetail(storeId = NaN) {
      const {
        id,
        name,
        email,
        address,
        bank,
        bank_account: bankAccount,
        bank_business_name: bankBusinessName,
        divide_ratio: divideRatio,
        train_price: trainPrice,
        nick_name: nickName,
        mobile,
        province,
        preset_limit: presetLimit,
        exam_preset_limit: examPresetLimit,
      } = await StoreManageApi.getStoreDetail(storeId);

      this.storeModalData = {
        ...this.storeModalData,
        id,
        name,
        email,
        address,
        bank,
        bankAccount,
        bankBusinessName,
        divideRatio,
        trainPrice,
        nickName,
        mobile,
        province,
        presetLimit,
        examPresetLimit,
      };
    },
    async fetchCommonStores(storeId) {
      const commonStores = await StoreManageApi.getCommonStores(storeId);
      this.$set(this.storeModalConfig, 'commonStores', commonStores);
    },
    async fetchOptometrists(storeId) {
      const optometrists = await StoreManageApi.getOptometrists(storeId);
      this.$set(this.storeModalConfig, 'optometrists', optometrists);
    },
    async fetchParentChannels() {
      const result = await ChannelManageApi.getChannelsByChannelName();
      const options = DataTransferUtil.originTransfer2Options(result);
      this.queryConfigChannelOptions = options;
      this.$set(this.storeModalConfig, 'channelOptions', options);
    },
    async fetchParentStores() {
      const result = await StoreManageApi.getStoresByStoreNameOrChannelId();
      const options = DataTransferUtil.originTransfer2Options(result);
      this.$set(this.storeModalConfig, 'storeOptions', options);
    },
    onSelect(selections) {
      this.$set(this.storeModalData, 'selectStoreIds', selections.map((selection) => selection.id));
    },
    async onStoreAction(
      action = STORE_ACTION.CREATE,
      visible = false,
      storeId = NaN,
      status = STATUS.ENABLE,
      row,
    ) {
      this.title = '';
      if (visible) {
        this.$refs.storeForm.resetFields();
      }

      if (action === STORE_ACTION.EDIT) {
        await this.fetchStoreDetail(storeId);
      } else if (action === STORE_ACTION.COMMON_STORE) {
        this.title = row?.name;
        await this.fetchCommonStores(storeId);
      } else if (action === STORE_ACTION.OPTOMETRIST) {
        await this.fetchOptometrists(storeId);
      } else if (action === STORE_ACTION.CREATE) {
        this.resetStoreModalData();
      } else {
        this.storeModalData = {
          ...this.storeModalData,
          id: storeId,
          bindType: REBIND_TYPE.CHANNEL,
          status,
        };
      }

      this.storeModalConfig = {
        ...this.storeModalConfig,
        visible,
        action,
        id: storeId,
        commonStoreKeyword: '',
        optometristKeyword: '',
        storeId: null,
        sgsIds: [],
        unBindStore: [],
        unBindSgs: [],
      };
    },
    async onUnBindAction(action, currentBindId) {
      const { id } = this.storeModalConfig;

      if (action === ALLOWED_PERMISSION.COMMON_STORE_UNBIND) {
        await StoreManageApi.unBindCommonStore(id, currentBindId);
        await this.fetchCommonStores(this.storeModalConfig.id);
      } else if (action === ALLOWED_PERMISSION.OPTOMETRIST_UNBIND) {
        await StoreManageApi.unBindOptometrist(currentBindId, this.storeModalConfig.id);
        await this.fetchOptometrists(this.storeModalConfig.id);
      }
    },
    async onModalConfirm() {
      this.$refs.storeForm.validate(async (valid) => {
        if (!valid) {
          return;
        }
        const {
          id,
          name,
          nickName,
          mobile,
          province,
          parentId,
          channelId,
          address,
          email,
          trainPrice,
          divideRatio,
          bank,
          bankAccount,
          bankBusinessName,
          bindType,
          bindChannelId,
          bindStoreId,
          newBindStoreId,
          newBindChannelId,
          selectStoreIds,
          status,
          presetLimit,
          examPresetLimit,
        } = this.storeModalData;

        let oldParentId = null;
        let newParentId = null;
        let oldChannelId = null;
        let newChannelId = null;

        switch (this.storeModalConfig.action) {
          case STORE_ACTION.CREATE:
            await StoreManageApi.createStore(
              name,
              nickName,
              mobile,
              province,
              email,
              address,
              trainPrice,
              divideRatio,
              bank,
              bankAccount,
              bankBusinessName,
              parentId,
              channelId,
            );
            break;
          case STORE_ACTION.REBIND:
            oldParentId = bindStoreId;
            oldChannelId = bindChannelId;

            if (bindType === REBIND_TYPE.PROVIDE) {
              newParentId = newBindStoreId;
            } else if (bindType === REBIND_TYPE.CHANNEL) {
              newChannelId = newBindChannelId;
            }

            await StoreManageApi.reDistributeParentChannelOrStore(
              selectStoreIds.toString(),
              oldParentId,
              newParentId,
              oldChannelId,
              newChannelId,
            );
            break;
          case STORE_ACTION.EDIT:
            await StoreManageApi.editStore(
              id,
              name,
              nickName,
              mobile,
              province,
              email,
              address,
              trainPrice,
              divideRatio,
              bank,
              bankAccount,
              bankBusinessName,
              presetLimit,
              examPresetLimit,
            );
            break;
          case STORE_ACTION.TOGGLE_STATUS:
            StoreManageApi.toggleStoreStatus(
              id,
              status === STATUS.ENABLE ? STATUS.DISABLE : STATUS.ENABLE,
            );
            break;
          case STORE_ACTION.RESET:
            StoreManageApi.resetPwd(id);
            break;
          default:
            break;
        }
        await this.onStoreAction(null);
        this.resetStoreModalData();

        await this.fetchStores();
      });
    },
    rebindModalValidator(rule, value, callback) {
      const { checkedFields } = rule;
      if (
        value === REBIND_TYPE.CHANNEL
        && !this.storeModalData[checkedFields[REBIND_TYPE.CHANNEL]]?.toString()
      ) {
        callback([TIPS.BIND_CHANNEL_ID]);
      } else if (
        value === REBIND_TYPE.PROVIDE
        && !this.storeModalData[checkedFields[REBIND_TYPE.PROVIDE]]?.toString()
      ) {
        callback([TIPS.BIND_STORE_ID]);
      }

      callback();
    },
    onBindTypeChange() {
      this.storeModalData = {
        ...this.storeModalData,
        parentId: null,
        channelId: null,
      };
    },
    onSelected(data) {
      this.$set(this.storeModalData, 'province', data.province.value);
    },
    createModalValidator(rule, value, callback) {
      const { bindType, parentId, channelId } = this.storeModalData;

      if (this.userType === USER_TYPE.ADMIN) {
        if (!bindType?.toString()) {
          callback(TIPS.PARENT_ID);
        }

        if (bindType === REBIND_TYPE.PROVIDE && !parentId?.toString()) {
          callback(TIPS.PARENT_ID);
        }

        if (bindType === REBIND_TYPE.CHANNEL && !channelId?.toString()) {
          callback(TIPS.PARENT_ID);
        }

        callback();
      } else if (this.userType === USER_TYPE.OUTLET && !parentId?.toString()) {
        callback(TIPS.PARENT_ID);
      } else if (this.userType === USER_TYPE.CHANNEL && !channelId?.toString()) {
        callback(TIPS.PARENT_ID);
      }

      callback();
    },
    async onExport() {
      const key = await StoreManageApi.export(this.queryData);
      await DataTransferUtil.downloadFileBySecretKey(key);
    },
    async onExportAll() {
      const key = await StoreManageApi.export();
      await DataTransferUtil.downloadFileBySecretKey(key);
    },
    async getUnbindSgsList() {
      const unBindSgs = await
      StoreManageApi.getUnbindSgsList(this.storeModalConfig.optometristKeyword);
      this.$set(this.storeModalConfig, 'unBindSgs', unBindSgs);
    },
    async getUnbindStoreList() {
      const unBindStore = await
      StoreManageApi.getUnbindStoreList(this.storeModalConfig.commonStoreKeyword);
      this.$set(this.storeModalConfig, 'unBindStore', unBindStore);
    },
    async bindSgs() {
      await StoreManageApi.bindSgs(this.storeModalConfig.sgsIds, this.storeModalConfig.id);
      await this.fetchOptometrists(this.storeModalConfig.id);
      // 绑定后刷新当前视光师列表
      await this.getUnbindSgsList();
    },
    async bindStores() {
      await StoreManageApi.bindStores(this.storeModalConfig.id, this.storeModalConfig.storeId);
      await this.fetchCommonStores(this.storeModalConfig.id);
    },

    handleStoreQrCode(row) {
      this.storeQrCodeModalConfig.value = `store_qr:${row.id}|${row.name}|${row.address}`;
      this.storeQrCodeModalConfig.visible = true;
      this.storeQrCodeModalConfig.name = row.name;
      this.storeQrCodeModalConfig.address = row.address;
    },
  },
};
</script>

<style lang="less" scoped>
.rebind-form-content {
  display: flex;

  > div {
    &:first-child {
      flex: 1 3 auto;
    }

    &:last-child {
      flex: 1 1 auto;
    }
  }
}

.modal-search-bar,
.modal-search-result-bar {
  display: flex;

  > div:first-child {
    flex: 1 1 auto;
  }
}

.divide-ratio {
  width: 100%;
}

.modalTable {
  height: 300px;
  overflow-y: auto;
}

.store-qrcode-modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  h3{
    margin-top: 20px;
  }
}
</style>
